import clsx from 'clsx';
import { Button } from '@/components/ui/button';
import { Image } from '@/components/ui/image';
import { List } from '@/components/ui/lists';
import { ListItem } from '@/components/ui/listitem';
import { ContactModal } from '@/components/modals';
import { useState } from 'react';
import { SociableKitScript } from '@/components/sociablekit-script';

export default function Home() {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <section
        className="hero"
        style={{ backgroundImage: `url('/img/knowus/bg.png')` }}
      >
        <div className="px-8">
          <h1 className={clsx('text-2xl md:text-5xl font-bold text-gray-800')}>
            SERVICES
          </h1>
          <p className="text-lg md:text-xl text-gray-900 mt-4 font-medium leading-7 md:leading-8 max-w-4xl">
            From buying to selling, I am on-call to help you with a range of
            real estate needs.
          </p>

          <Button className="text-lg my-4" onClick={() => setOpen(true)}>
            CONNECT WITH JEN
          </Button>
        </div>
      </section>
      <section className="grid grid-cols-1 lg:grid-cols-2 gap-8 px-8 my-10 lg:my-20">
        <div>
          <h2 className="font-extrabold text-2xl md:text-3xl">
            Why Work with Jen
          </h2>
          <p className="text-base my-3 md:my-4">
            When it comes to buying your perfect home, there are many steps and
            decisions to navigate along the way. From buying to selling, Jen and
            her team are on-call to help you with a range of real estate needs.
          </p>
          <p>You can rest assured knowing Jen will:</p>
          <div className="relative layer-image w-full block lg:hidden my-8">
            <div className="relative h-64 md:h-96 lg:h-120 lg:ml-6 lg:mt-6 z-20">
              <Image src="/img/sec2img.png" layout="fill" alt="Section Image" />
            </div>
          </div>
          <List type="checked">
            <ListItem>Take the time to educate you on your options.</ListItem>
            <ListItem>
              Tailor your search to the homes that best fit your wish list.
            </ListItem>
            <ListItem>
              Stay ahead of market trends and offer professional advice and
              guidance.
            </ListItem>
            <ListItem>
              Connect you with the right professionals and resources.
            </ListItem>
            <ListItem>
              Help you make the decision that is best suited to your unique
              goals.
            </ListItem>
            <ListItem>
              Negotiate on your behalf and put you in an ideal position when
              making an offer.
            </ListItem>
          </List>
        </div>
        <div className="relative layer-image w-full hidden lg:block">
          <div className="relative h-64 md:h-120 md:ml-6 md:mt-6 z-20">
            <Image src="/img/sec2img.png" layout="fill" alt="Section Image" />
          </div>
        </div>
      </section>

      {/* <section className="px-8 flex flex-col-reverse lg:grid lg:grid-cols-12 mb-4 lg:mb-0 lg:my-8">
        <div className="col-span-8 mt-8 lg:mt-0">
          <div className="reduced-prod">
            <div className="z-10 relative h-full w-full">
              <div className="relative h-72 lg:h-120 w-full">
                <Image
                  src="/img/reducedimg.png"
                  className="h-full w-full"
                  layout="fill"
                  objectFit="fill"
                  alt="Reduced Image"
                />
              </div>
              <div className="reduced-info absolute bottom-5 px-6 lg:px-16 left-0 right-0">
                <label className="bg-white border-t border-white w-full relative -top-3 py-2 px-4 uppercase md:text-base text-xs font-semibold rounded-sm">
                  Properties
                </label>
                <h2 className="text-white text-lg md:text-5xl font-semibold">
                  Toronto Apartment Ave.
                </h2>
                <div className="flex items-center justify-between my-1 md:my-3">
                  <Link
                    className="text-white text-base md:text-xl font-semibold md:font-normal"
                    href="/"
                  >
                    Learn More
                  </Link>
                  <span className="text-base md:text-4xl text-white font-semibold md:font-normal">
                    $ 2,400
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-4">
          <div className="lg:pl-7">
            <h2 className="text-3xl md:text-4xl lg:mt-4 font-bold">Reduced</h2>
            <p className="text-base md:text-lg mt-2 text-gray-600">
              With the expertise and experience in diverse fields, I am
              dedicated to make a positive difference for my clients as am able
              to assist right from the start till the completion of the deal.
            </p>
            <Button className="mt-4 text-lg font-semibold">See how</Button>
          </div>
        </div>
      </section> */}

      {/* <section className="px-8 lg:my-44 relative flex items-center lg:h-96 flex-wrap my-10">
        <div className="hidden lg:block lg:absolute md:right-0 my-8 md:my-0">
          <div className="relative h-52 md:h-180 w-60 md:w-192">
            <Image
              src="/img/imgview.png"
              layout="fill"
              objectFit="contain"
              alt="Image View"
            />
          </div>
        </div>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-6">
            <div className="secvalue-home">
              <h2 className="text-3xl md:text-4xl font-bold mb-6">
                Value of your home
              </h2>
              <div className="flex items-center justify-center lg:hidden lg:absolute md:right-0 my-4 md:my-0">
                <div className="relative h-64 lg:h-180 w-80 lg:w-192">
                  <Image
                    src="/img/imgview.png"
                    layout="fill"
                    objectFit="contain"
                    alt="Image View"
                  />
                </div>
              </div>
              <p className="md:text-lg mb-3 md:mb-6 text-gray-600">
                At Royal LePage, Helping you is what we do , and our philosophy
                is as true today as it was over 100 years ago when our company
                was founded. It&lsquo;s what drives us forward in the work we do
                - helping Canadians buy and sell their homes, and in the steps
                we take to better our community.
              </p>
              <p className="md:text-lg mb-3 md:mb-6 text-gray-600">
                We&lsquo;re not just expert sales professionals, we are
                relationship builders. We&lsquo;re here to go above and beyond
                to find you a place to call home, becoming a lifelong resource
                for you to lean on whenever you need us. We strive to build a
                stronger community - one where our clients, neighbours, and
                friends are proud to live, work and play.
              </p>
              <Link
                href="/"
                className={clsx(
                  "text-base md:text-xl relative font-semibold",
                  style["view-availability"]
                )}
              >
                View Availability
              </Link>
            </div>
          </div>
        </div>
      </section> */}
      {/* 
      <section className="px-8 grid lg:grid-cols-2 gap-8 md:my-8">
        <div className="col-md-6 py-4 px-4 md:py-8 md:px-10 bg-brown-500 hidden md:block">
          <div className="testomonial-section">
            <div className="relative md:h-10 md:w-10 h-6 w-6">
              <Image
                src="/img/qoute.png"
                objectFit="contain"
                layout="fill"
                alt="Quute"
              />
            </div>
            <p className="text-white my-2 md:my-5 leading-6 md:leading-7 font-semibold md:font-medium text-base">
              With the expertise and experience in diverse fields, I am
              dedicated to make a positive difference for my clients as am able
              to assist right from the start till the completion of the deal. As
              a trusted Realtor in the community, am pleased to assist you in
              getting deals completed at the best price so anytime you need
              assistance in buying, selling, leasing or investing, I will be
              pleased to help you.
            </p>
            <div className="flex items-center space-x-4">
              <div className="h-14 w-14 relative">
                <Image
                  src="/img/namef.png"
                  layout="fill"
                  objectFit="contain"
                  alt="Name Image"
                />
              </div>
              <span className="text-lg md:text-xl text-white font-semibold">
                Joe Jhon C.
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-6 flex items-center">
          <div className="testimonial-ctn">
            <h2 className="text-3xl md:text-4xl font-semibold mb-4 md:mb-8">
              Testimonial books
            </h2>
            <p className="text-base md:text-lg text-gray-600">
              With the expertise and experience in diverse fields, I am
              dedicated to make a positive difference for my clients as am able
              to assist right from the start till the completion of the deal. As
              a trusted Realtor in the community, am pleased to assist you in
              getting deals completed at the best price so anytime you need
              assistance in buying, selling, leasing or investing, I will be
              pleased to help you.
            </p>
          </div>
        </div>
      </section>

      <section className="grid grid-cols-1 lg:grid-cols-2 md:gap-8 my-0 md:my-8 px-8">
        <div>
          <div className="lg:my-8">
            <div className="grid lg:grid-cols-12 gap-8">
              <div className="lg:col-span-5 hidden lg:block">
                <div className="flex items-center justify-center">
                  <div className="relative h-52 w-full">
                    <Image
                      src="/img/homeblogimg.png"
                      layout="fill"
                      alt="Home Blog Image"
                    />
                  </div>
                </div>
              </div>
              <div className="md:col-span-7">
                <div className="md:mt-4">
                  <h2 className="text-base md:text-lg font-extrabold md:font-bold">
                    YOUR HANDS-ON BUYING EXPERTS
                  </h2>
                  <div className="block lg:hidden relative h-52 w-full mt-4">
                    <Image
                      src="/img/homeblogimg.png"
                      layout="fill"
                      alt="Home Blog Image"
                      objectFit="contain"
                    />
                  </div>
                  <p className="text-base font-normal my-2 text-gray-600">
                    With the expertise and experience in diverse fields, I am
                    dedicated to make a positive difference for my clients as am
                    able to assist right from the start till the completion of
                    the deal.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="my-4 md:my-8">
            <div className="grid lg:grid-cols-12 gap-8">
              <div className="md:col-span-5 hidden lg:block">
                <div className="flex items-center justify-center">
                  <div className="relative h-52 w-full">
                    <Image
                      src="/img/homeblogimg1.png"
                      layout="fill"
                      alt="Home Blog Image"
                    />
                  </div>
                </div>
              </div>
              <div className="md:col-span-7">
                <div className="md:mt-4">
                  <h2 className="text-base md:text-lg font-extrabold md:font-bold">
                    YOUR HANDS-ON BUYING EXPERTS
                  </h2>
                  <div className="block lg:hidden relative h-52 w-full mt-4">
                    <Image
                      src="/img/homeblogimg1.png"
                      layout="fill"
                      alt="Home Blog Image"
                      objectFit="contain"
                    />
                  </div>
                  <p className="text-base font-normal my-2 text-gray-600">
                    With the expertise and experience in diverse fields, I am
                    dedicated to make a positive difference for my clients as am
                    able to assist right from the start till the completion of
                    the deal.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden md:block">
          <div className="relative w-full h-full">
            <div className="relative h-120 w-full">
              <Image
                src="/img/homeblogart.png"
                layout="fill"
                alt="Home Blog Part"
              />
            </div>
            <div className="absolute bottom-0 p-10">
              <h2 className="text-white text-base md:text-2xl font-semibold mb-2">
                YOUR HANDS-ON BUYING EXPERTS
              </h2>
              <p className="text-white text-base leading-6">
                With the expertise and experience in diverse fields, I am
                dedicated to make a positive difference for my clients as am
                able to assist right from the start till the completion of the
                deal.
              </p>
            </div>
          </div>
        </div>
      </section> */}

      <section className="container mx-auto mt-4 mb-4">
        <div className="sk-ww-google-reviews" data-embed-id="70295"></div>
        <SociableKitScript src="https://www.sociablekit.com/app/embed/google-reviews/widget.js" />
      </section>

      {/* <section>
        <div className="container mx-auto">
          <div className="instagram-section">
            <h2 className="text-center font-semibold text-xl md:text-2xl">
              INSTAGRAM <span>&#64;{InfoJson.instagramId}</span>
            </h2>
            <div className="flex items-center flex-wrap justify-center">
              {InstagramPosts.map((el) => (
                <div className="group relative mt-3" key={el.image}>
                  <div className="relative w-36 md:w-60 h-40 md:h-60">
                    <Image src={el.image} layout="fill" alt="Instagram Post" />
                  </div>
                  <div className="overlay absolute inset-0 bg-black bg-opacity-20 z-10 group-hover:scale-100 scale-0 transform duration-300 flex items-center justify-center">
                    <a
                      href={`https://www.instagram.com/${InfoJson.instagramId}${el.path}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="relative h-10 w-10">
                        <Image
                          src="/img/instagram.png"
                          layout="fill"
                          alt="Instgram Icon"
                        />
                      </div>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section> */}
      <ContactModal open={open} toggleModal={() => setOpen((prev) => !prev)} />
    </div>
  );
}
