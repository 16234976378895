import { useEffect } from "react";

export function SociableKitScript({ src }: { src: string }) {
    useEffect(() => {
        const element = document.createElement('script');
        element.type = 'text/javascript';
        element.src = src;
        document.body.appendChild(element);
    }, [src]);

    return null;
}
